import { useContext, useState } from "react";
import AlertPopup from "../Alert/alert";
import { useNavigate } from "react-router";
import axios from "axios";
import { AlertContext } from "../../App";
import { Link } from "react-router-dom";

const NewEvent = () => {
  const [eventName, setEventName] = useState();
  const [description, setDescription] = useState();
  const [total, setTotal] = useState();
  const [attribute1, setAttribute1] = useState();
  const [attribute2, setAttribute2] = useState();
  const [attribute3, setAttribute3] = useState();
  const [attribute4, setAttribute4] = useState();
  const [attribute5, setAttribute5] = useState();
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const alertContext = useContext(AlertContext);

  const handleNewEvent = async (e) => {
    e.preventDefault();
    const user = JSON.parse(localStorage.getItem("user"));
    const file = document.querySelector("input[type=file]").files[0];
    setError(null);

    console.log("attributes 1", attribute1?.key);
    console.log("attributes", attribute2);
    console.log("attributes", attribute3);
    console.log("attributes", attribute4);
    console.log("attributes", attribute5);

    if (!user) {
      return navigate("/");
    }
    const errors = [];

    if (!attribute1) {
      errors.push("At least one metadata field is required");
    }

    if (!eventName || eventName.length === 0) {
      errors.push("Event name is required");
    }
    if (!description || description?.length === 0) {
      errors.push("Description is required");
    }

    if (!file || file?.length === 0) {
      errors.push("File is required");
    }

    // File size should be less than 10MB
    if (file?.size > 10000000) {
      errors.push("File size should be less than 10MB");
    }

    if (
      !file.name.toLowerCase().includes("jpg") &&
      !file.name.toLowerCase().includes("png") &&
      !file.name.toLowerCase().includes("gif") &&
      !file.name.toLowerCase().includes("jpeg")
    ) {
      errors.push("File must be png or jpg or gif or jpeg");
    }

    if (!total || total <= 0) {
      errors.push("Total is required");
    }
    const key = attribute1?.key;
    const val = attribute1?.val;
    const extra = {
      attributes: [{ trait_type: key, value: val }],
    };

    if (attribute2) {
      extra.attributes.push({
        trait_type: attribute2?.key,
        value: attribute2?.val,
      });
    }

    if (attribute3) {
      extra.attributes.push({
        trait_type: attribute3?.key,
        value: attribute3?.val,
      });
    }

    if (attribute4) {
      extra.attributes.push({
        trait_type: attribute4?.key,
        value: attribute4?.val,
      });
    }

    if (attribute5) {
      extra.attributes.push({
        trait_type: attribute5?.key,
        value: attribute5?.val,
      });
    }

    // Do we need this?
    try {
      JSON.parse(JSON.stringify(extra));
      console.log("valid");
    } catch {
      errors.push("Extra is not valid JSON");
    }

    console.log("errors", errors);
    if (errors.length > 0) {
      setError(errors);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    let ipfsHash;

    try {
      const ipfs = await axios.post(
        process.env.REACT_APP_BASEURL + "/nftarticle",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      ipfsHash = `ipfs://${ipfs?.data?.IpfsHash}`;
    } catch (err) {
      console.log("err", err);
    }

    const res = await fetch(process.env.REACT_APP_BASEURL + "/members/pins", {
      method: "post",
      body: JSON.stringify({
        name: eventName,
        mintnetwork: "POLYGON",
        description,
        artifact: ipfsHash,
        maxtokens: total,
        limitperperson: 1,
        extra: extra,
      }),
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${user.sessionToken}`,
      },
    });
    const data = await res.json();
    if (data["error"]) {
      setError(data["error"]);
      return;
    } else {
      alertContext.show = true;
      alertContext.variant = "primary";
      alertContext.msg = "A new pins has been created!";
      navigate("/dashboard/pins");
    }
  };

  const handleErrorpopup = () => {
    setError(null);
  };

  const handleMetadata = (e) => {
    const id = e.target.id;
    const val = e.target.value;
    const fieldNum = e.target.id.split("-")?.[1];
    if (fieldNum === "1") {
      if (id.includes("key")) {
        setAttribute1((prevState) => {
          return {
            ...prevState,
            key: val,
          };
        });
      }

      if (id.includes("val")) {
        setAttribute1((prevState) => {
          return {
            ...prevState,
            val,
          };
        });
      }
    }

    if (fieldNum === "2") {
      if (id.includes("key")) {
        setAttribute2((prevState) => {
          return {
            ...prevState,
            key: val,
          };
        });
      }

      if (id.includes("val")) {
        setAttribute2((prevState) => {
          return {
            ...prevState,
            val,
          };
        });
      }
    }
    if (fieldNum === "3") {
      if (id.includes("key")) {
        setAttribute3((prevState) => {
          return {
            ...prevState,
            key: val,
          };
        });
      }

      if (id.includes("val")) {
        setAttribute3((prevState) => {
          return {
            ...prevState,
            val,
          };
        });
      }
    }
    if (fieldNum === "4") {
      if (id.includes("key")) {
        setAttribute4((prevState) => {
          return {
            ...prevState,
            key: val,
          };
        });
      }

      if (id.includes("val")) {
        setAttribute4((prevState) => {
          return {
            ...prevState,
            val,
          };
        });
      }
    }
    if (fieldNum === "5") {
      if (id.includes("key")) {
        setAttribute5((prevState) => {
          return {
            ...prevState,
            key: val,
          };
        });
      }

      if (id.includes("val")) {
        setAttribute5((prevState) => {
          return {
            ...prevState,
            val,
          };
        });
      }
    }
  };

  return (
    <div className="container">
      {error && (
        <AlertPopup
          message={error}
          variant="danger"
          setShow={handleErrorpopup}
        />
      )}
      <div className="col-md-2 col-sm-4 col-lg-10 mx-auto">
        <form
          method="post"
          encType="multipart/form-data"
          onSubmit={handleNewEvent}
        >
          <Link to="/dashboard/pins" style={{ textDecoration: "none" }}>
            &larr; Back to Pins
          </Link>
          <h3 className="login-heading mb-4">New Pin</h3>
          <div className="form-group mb-4">
            <label htmlFor="name">Pin Name *</label>
            <input
              type="text"
              name="name"
              className="form-control"
              onChange={(e) => setEventName(e.target.value)}
              required
            />
          </div>
          <div className="form-group mb-4">
            <label htmlFor="name">Pin Type </label>
            <div className="form-check">
              <input
                type="radio"
                name="typeofPin"
                className="form-check-input"
                value="Digital Souvenir"
                checked
                readOnly
              />
              <label className="form-check-label" htmlFor="typeofPin">
                Digital Souvenir
              </label>

              <br />

              <input
                type="radio"
                name="typeofPin2"
                className="form-check-input"
                value="Digital Reward"
                disabled
              />
              <label className="form-check-label" htmlFor="typeofPin2">
                Digital Reward
              </label>
            </div>
          </div>
          <div className="form-group mb-4">
            <label htmlFor="description">Description *</label>
            <textarea
              rows={5}
              className="form-control"
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </div>
          <div className="form-floating mb-3">
            <hr />
            <h3>Digital Asset (NFT Details)</h3>
          </div>
          <div className="form-group mb-4">
            <label htmlFor="artifact">Asset (JPG or PNG or GIF) *</label>
            <input
              type="file"
              className="form-control"
              required
              name="artifact"
              accept="image/*"
              placeholder="jpeg or png or gif image"
            />
          </div>
          <div className="form-group mb-4">
            <label htmlFor="location">Total *</label>
            <input
              type="number"
              className="form-control"
              required
              min={0}
              onChange={(e) => setTotal(e.target.value)}
            />
          </div>
          <div className="form-floating mb-3">
            <hr />
            <h3>Properties</h3>
          </div>
          <div className="form-group mb-4">
            <div className="form-group mb-4">
              <label htmlFor="location">Keys &amp; Values will be stored inside each NFT minted.</label><br /><br />
              <div style={{ display: "flex", gap: "2rem" }}>
                <input
                  type="text"
                  className="form-control"
                  required
                  placeholder="Key"
                  id="key-1"
                  onChange={handleMetadata}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Value"
                  id="val-1"
                  required
                  onChange={handleMetadata}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  margin: "1rem 0 1rem 0",
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  id="key-2"
                  placeholder="Key"
                  onChange={handleMetadata}
                />
                <input
                  type="text"
                  className="form-control"
                  id="val-2"
                  placeholder="Value"
                  onChange={handleMetadata}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  margin: "1rem 0 1rem 0",
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  placeholder="Key"
                  id="key-3"
                  onChange={handleMetadata}
                />
                <input
                  type="text"
                  className="form-control"
                  placeholder="Value"
                  id="val-3"
                  onChange={handleMetadata}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  margin: "1rem 0 1rem 0",
                }}
              >
                <input
                  type="text"
                  className="form-control"
                  id="key-4"
                  placeholder="Key"
                  onChange={handleMetadata}
                />
                <input
                  type="text"
                  className="form-control"
                  id="val-4"
                  placeholder="Value"
                  onChange={handleMetadata}
                />
              </div>
              <div style={{ display: "flex", gap: "2rem" }}>
                <input
                  type="text"
                  className="form-control"
                  id="key-5"
                  placeholder="Key"
                  onChange={handleMetadata}
                />
                <input
                  type="text"
                  className="form-control"
                  id="val-5"
                  placeholder="Value"
                  onChange={handleMetadata}
                />
              </div>
            </div>
          </div>
          <div className="d-grid">
            <button
              type="submit"
              className="btn btn-lg btn-primary btn-login text-uppercase fw-bold mb-2"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NewEvent;
