import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router";
import { AlertContext } from "../../App";

const TotalBilling = () => {
  const [Billing, setBilling] = useState();

  const alertContext = useContext(AlertContext);

  useEffect(() => {
    getBilling();
  }, [alertContext, Navigate]);
  const getBilling = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (!user) {
      return Navigate("/");
    }
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASEURL}/members/billing`,
        {
          headers: {
            Authorization: `Bearer ${user.sessionToken}`,
          },
        }
      );
      const data = await res.json();
      setBilling(data);
      console.log(data);
    } catch (err) {
      alertContext.show = true;
      alertContext.variant = "danger";
      if (err.toString()?.includes("Forbidden")) {
        alertContext.msg = "Session expired, please sign in";
        localStorage.removeItem("user");
        Navigate("/");
      } else {
        alertContext.msg = "Error getting pins";
      }
    }
  };
  const formatDate = (date) => {
    const options = { day: "2-digit", month: "long", year: "numeric" };
    const formattedDate = new Date(date).toLocaleDateString("en-US", options);
    return formattedDate;
  };
  return (
    <div className="container">
      <br />
      <h2>Billing</h2>
      <div className="row">
        <div className="col-2">
          <b>Date</b>
        </div>
        <div className="col" style={{ textAlign: "center" }}>
          <b>Pin Type</b>
        </div>
        <div className="col" style={{ textAlign: "center" }}>
          <b>Trans Type</b>
        </div>
        <div className="col" style={{ textAlign: "center" }}>
          <b>Tokens</b>
        </div>
      </div>

      {Billing &&
        Billing.map((data) => {
            const toTitleCase = (str) => {
            return str.replace(/\w\S*/g, (txt) => {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
            };

            return (
            <div className="row">
              <div className="col-2" style={{ verticalAlign: "top" }}>
              {formatDate(data.created_at)}
              </div>
              <div className="col" style={{ textAlign: "center" }}>
              {toTitleCase(data.pinstype)}
              </div>
              <div className="col" style={{ textAlign: "center" }}>
              {data.transtype === "SIGNUP_BONUS" ? "Signup Bonus" : data.transtype}
              </div>
              <div className="col" style={{ textAlign: "center" }}>
              {data.tokens}
              </div>
            </div>
            );
        })}
    </div>
  );
};

export default TotalBilling;
