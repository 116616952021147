import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import AlertPopup from "../Alert/alert";
import { AlertContext } from "../../App";
import mainPicture from "../../images/signup.jpg";

const Signup = () => {
    const [companyName, setCompanyName] = useState();
    const [address, setAddress] = useState();
    const [phone, setPhone] = useState();
    const [customer_email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setConfirmPassword] = useState();
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const alertContext = useContext(AlertContext);

    const handleSignUp = () => {
        const errors = [];

        let count = 0;

        count += /[a-z]/.test(password) ? 1 : 0;
        count += /[A-Z]/.test(password) ? 1 : 0;
        count += /\d/.test(password) ? 1 : 0;
        count += /\W/.test(password) ? 1 : 0;

        if (!companyName || companyName.length === 0) {
            errors.push("Company name is required");
        }
        if (!password || password?.length === 0) {
            errors.push("Password is required");
        } else if (password?.length < 5 || password?.length > 20) {
            errors.push("Password length must be between 5 & 20");
        } else if (count < 4) {
            errors.push(
                "Password must contain on uppercase letter, one digit, and one symbol."
            );
        } else if (confirmPassword !== password) {
            errors.push("Passwords don't match");
        }

        if (!customer_email || customer_email?.length === 0) {
            errors.push("Email is required");
        }

        if (errors.length > 0) {
            setError(errors);
            return;
        }

        fetch(process.env.REACT_APP_BASEURL + "/members/signup", {
            method: "post",
            body: JSON.stringify({
                name: companyName,
                customer_email,
                password,
                address,
                phone,
            }),
            headers: { "content-type": "application/json" },
        })
            .then((res) => res.json())
            .then((data) => {
                if (data["error"]) {
                    setError(data["error"]);
                } else {
                    alertContext.show = true;
                    alertContext.msg =
                        "Congratulations! Your signup was successful. Please check your email for verification.";
                    navigate("/signin");
                }
            })
            .catch((err) => {
                setError(
                    "Error occurred during signup. Contact contact@clifty.io for support."
                );
            });
    };

    const handleErrorpopup = () => {
        setError(null);
    };

    const keyDownHandler = (event) => {
        if (event.key === "Enter") {
            handleSignUp();
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", keyDownHandler);
        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, [
        companyName,
        address,
        phone,
        customer_email,
        password,
        confirmPassword,
        keyDownHandler,
    ]);

    return (
        <div className="row">
            <div className="col-md-6">
                <div className="demo-content">
                    <img src={mainPicture} alt="" style={{ width: "100%" }} />
                </div>
            </div>
            <div className="col-md-6">
                <div className="demo-content bg-alt"></div>
                <div style={{ maxWidth: "600px", padding: "0 2rem" }}>
                    <h2>Clifty for Members</h2>
                    Sign up for a free account to start making digital
                    Collectibles using blockchain technology (ERC-721 standard)
                    on Polygon network.
                    <hr />
                    <br />
                    {error && (
                        <AlertPopup
                            message={error}
                            variant="danger"
                            setShow={handleErrorpopup}
                        />
                    )}
                    <div
                        className="mx-auto"
                        style={{ maxWidth: "600px", padding: "0 2rem" }}>
                        <h3 className="login-heading mb-4">Member Sign up</h3>
                        <form>
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="company"
                                    onChange={(e) =>
                                        setCompanyName(e.target.value)
                                    }
                                    autoComplete="off"
                                    max={100}
                                    maxLength={100}
                                />
                                <label htmlFor="company">Company Name</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="address"
                                    onChange={(e) => setAddress(e.target.value)}
                                    autoComplete="off"
                                />
                                <label htmlFor="address">Address</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    name="phone"
                                    onChange={(e) => setPhone(e.target.value)}
                                    autoComplete="off"
                                />
                                <label htmlFor="phone">Phone</label>
                            </div>
                            <div className="form-floating mb-3">
                                <hr />
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    type="email"
                                    className="form-control"
                                    name="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    autoComplete="off"
                                    required
                                    max={200}
                                    maxLength={200}
                                />
                                <label htmlFor="email">Email address</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    name="password"
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    autoComplete="off"
                                    min={8}
                                    minLength={8}
                                />
                                <label htmlFor="password">Password</label>
                            </div>
                            <div className="form-floating mb-3">
                                <input
                                    type="password"
                                    className="form-control"
                                    name="password_confirmation"
                                    onChange={(e) =>
                                        setConfirmPassword(e.target.value)
                                    }
                                    autoComplete="off"
                                />
                                <label htmlFor="password_confirmation">
                                    Confirm Password
                                </label>
                            </div>
                            <div
                                className="col-md-2 col-lg-4 mx-auto"
                                style={{
                                    width: "100%",
                                    textAlign: "center",
                                    marginBottom: "1rem",
                                    fontSize: "14px",
                                }}>
                                By clicking the Signup button below, you agree
                                to our <a href="/terms">Terms of Service</a> and{" "}
                                <a href="/privacy">Privacy Policy</a>
                            </div>
                            <div className="d-grid">
                                <button
                                    className="btn btn-lg btn-primary btn-login text-uppercase fw-bold"
                                    style={{ fontSize: "16px" }}
                                    onClick={handleSignUp}
                                    type="button">
                                    Sign up
                                </button>
                            </div>
                        </form>
                    </div>
                    <br />
                    <br />
                    <div className="mx-auto text-center mx-2">
                        <span>
                            If you are already a member, please{" "}
                            <a href="/signin">Sign In</a> here.
                        </span>
                    </div>
                    <div>
                        <br />
                        <hr />
                        <div className="mx-auto text-center mx-2">
                            <span>
                                If you would like to Sign up as a Collector to
                                collect and store Digital Collectibles, please{" "}
                                <a href="https://collector.clifty.io/signup">
                                    Sign Up
                                </a>{" "}
                                here.
                            </span>
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Signup;
